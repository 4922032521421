<template>
  <div class="news">
    新闻咨询
  </div>
</template>

<script>
export default {
  name: 'News'
}
</script>

<style lang="scss" scoped>
  .news {
    width: 1920px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    color: #666;
    background-color: #fff;
  }
</style>